var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"book-card"},[_c('div',{staticClass:"book-card-header"},[(_vm.isPast)?_c('v-chip',{staticClass:"mr-1 ml-1",attrs:{"small":"","label":""}},[_vm._v(" "+_vm._s(_vm.$t('isPast'))+" ")]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.$vuetify.breakpoint.smAndDown),expression:"$vuetify.breakpoint.smAndDown"}]},[(_vm.bookIsRejected())?_c('v-chip',{staticClass:"mr-1 ml-1",attrs:{"small":"","color":"error","label":""}},[_vm._v(" "+_vm._s(_vm.$t('bookIsRejected'))+" ")]):(_vm.bookIsAccepted())?_c('v-chip',{staticClass:"mr-1 ml-1",attrs:{"small":"","color":"accent","label":""}},[_vm._v(" "+_vm._s(_vm.$t('bookIsAccepted'))+" ")]):_c('v-chip',{staticClass:"mr-1 ml-1",attrs:{"small":"","color":"warning","label":""}},[_vm._v(" "+_vm._s(_vm.$t('bookIsPending'))+" ")])],1)],1),_c('div',{staticClass:"book-card-content"},[_c('div',{staticClass:"book-card-content-info"},[_c('div',[_c('div',{staticClass:"text-body-1 mc-font",class:{
              'text-decoration-line-through': _vm.bookIsRejected(),
              'text-muted': _vm.bookIsRejected(),
            },on:{"click":_vm.gotoDetails}},[_c('strong',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm._f("date")(_vm.startDate,'long')))])]),_c('div',{staticClass:"text-body-2 mc-font text-muted",class:{
              'text-decoration-line-through': _vm.bookIsRejected(),
            },on:{"click":_vm.gotoDetails}},[_c('span',{attrs:{"dir":_vm.$vuetify.rtl ? 'rtl' : 'ltr'}},[_vm._v(" "+_vm._s(_vm.duration)+" ")])])]),_c('div',[_c('div',{staticClass:"text-body-1 mc-font",class:{
              'text-decoration-line-through': _vm.bookIsRejected(),
              'text-muted': _vm.bookIsRejected(),
            },on:{"click":_vm.gotoDetails}},[_c('strong',[_vm._v(_vm._s(_vm.$t('youAndTheBooker'))+" "+_vm._s(_vm.book.email))])]),_c('div',{staticClass:"text-body-2 mc-font text-muted",class:{
              'text-decoration-line-through': _vm.bookIsRejected(),
            },on:{"click":_vm.gotoDetails}},[_c('span',{attrs:{"dir":_vm.$vuetify.rtl ? 'rtl' : 'ltr'}},[_c('span',{staticClass:"mx-1"},[_vm._v(_vm._s(_vm.$t('bookingMadeOn')))]),_c('span',{attrs:{"dir":"ltr"}},[_vm._v(_vm._s(_vm._f("date")(_vm.book.created_at,'datetime')))])])])])]),(_vm.$vuetify.breakpoint.mdAndUp)?_c('div',{staticClass:"book-card-contetn-actions"},[(_vm.bookIsRejected())?_c('v-chip',{staticClass:"mr-1 ml-1",attrs:{"small":"","color":"error","label":""}},[_vm._v(_vm._s(_vm.$t('bookIsRejected')))]):(_vm.bookIsAccepted())?_c('v-chip',{staticClass:"mr-1 ml-1",attrs:{"small":"","color":"accent","label":""}},[_vm._v(_vm._s(_vm.$t('bookIsAccepted')))]):_c('v-chip',{staticClass:"mr-1 ml-1",attrs:{"small":"","color":"warning","label":""}},[_vm._v(_vm._s(_vm.$t('bookIsPending')))]),_c('v-menu',{attrs:{"offset-y":"","bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function({ on, attrs }){return [_c('v-btn-toggle',{attrs:{"dir":_vm.$vuetify.rtl ? 'rtl' : 'ltr',"dense":"","color":"info"}},[_c('v-btn',{attrs:{"depressed":"","text":"","color":"info"},on:{"click":_vm.gotoDetails}},[_vm._v(" "+_vm._s(_vm.$t('details'))+" ")]),_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":_vm.bookIsPast,"icon":"","color":"info"}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"small":"","color":"info"}},[_vm._v(" fas fa-chevron-down ")])],1)],1)]}}],null,false,2840260675)},[_c('v-list',{attrs:{"color":"white"}},[(_vm.bookingPage.confirm && _vm.book.status === 'pending')?_c('v-list-item',{attrs:{"disabled":_vm.bookIsPast},on:{"click":_vm.acceptBook}},[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t('acceptBook')))])])],1):_vm._e(),_c('v-list-item',{attrs:{"disabled":_vm.bookIsRejected()},on:{"click":_vm.rejectBook}},[_c('v-list-item-title',[_c('span',[_vm._v(_vm._s(_vm.$t('rejectBook')))])])],1)],1)],1)],1):_vm._e()])])
}
var staticRenderFns = []

export { render, staticRenderFns }